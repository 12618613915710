<template>
  <div>

    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="3">
          <b-form-group :label="$t('product')">
            <v-select :value="$store.productId" v-model="selectedProduct"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="products" label="productId"
              @input="inputSelect('products')" :reduce="(products) => products.productId"
              :placeholder="$t('select_option')" />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.brands')">
            <v-select :value="$store.brandId" v-model="selectedBrand"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="brands" label="brandId"
              @input="inputSelect('brands')" :reduce="(brands) => brands.brandId" :placeholder="$t('select_option')" />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>

          <!-- search -->
          <!--  <b-col md="3" class="d-flex align-items-center justify-content-center mb-1">
            <b-form-input v-model="search" class="d-inline-block mr-1" placeholder="Filtrar" />
          </b-col> -->
          <b-table md="12" :items="games" :fields="fields" class="scroll sticky-header" responsive>
            <template #cell(referenceId)="data">
              <div class="mr-1 text-center">
                <b-button :to="{
                  name: 'apps-reports-liveBetting-detail',
                  params: { id: data.item.transactionID, userId: dataIdUser, status: data.item.transaction_status },
                }" variant="outline-primary">{{ data.item.referenceId }}</b-button>


              </div>
            </template>
            <template #cell(game.url_image)="data">
              <b-img :src="data.item.game.url_image" blank-color="#ccc" width="64" alt="placeholder" />
            </template>
            <template #cell(sort)="data">
              <div class="form-group">
                <input type="text" class="form-control" style="min-width: 80px !important;" name="link" id="link" v-model="data.item.sort" @blur="updateSort(data.item._id, data.item.game, data.item.sort, data.item.productId)">
              </div>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountPrize)="data">
              {{ data.item.amountPrize | currency({ symbol: "" }) }}
            </template>
            <template #cell(amountRetired)="data">
              {{ data.item.amountRetired | currency({ symbol: "" }) }}
            </template>
            <template #cell(game.tags)="data">
              <span v-if="data.item.game.tags == ''"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle;">
                {{ $t('labels.no_tags') }}</span>
            </template>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | moment }}
            </template>
            <template #cell(game.status)="data">
              <span v-if="data.item.game.status == true"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: rgba(40,199,111,.12); color: #28c76f !important;">
                {{ $t('labels.active') }}</span>
              <span v-else-if="data.item.game.status == false"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: rgba(253, 42, 47,.12); color: red;">
                {{ $t('labels.inactive') }}</span>

            </template>
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret no-flip menu-class="list" class="list"
                :right="$store.state.appConfig.isRTL">
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>
                <div>
                  <b-dropdown-item @click="">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("buttons.edit") }}</span>
                  </b-dropdown-item>


                  <b-dropdown-item @click="">

                    <feather-icon icon="RefreshCcwIcon" />
                    <span class="align-middle ml-50">{{
                      $t('buttons.updateProducts')
                    }}
                    </span>
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import axios from "@/libs/axios";
import axiosDef from "axios";
import lobbyStoreModule from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/store/lobbyStoreModule";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
import ExportButton from "@/views/pages/export/ExportButton.vue";

export default {
  components: {
    ExportButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [
      { label: this.$t('labels.image'), key: "game.url_image" },
        { label: this.$t('labels.name'), key: "game.gameName" },
        { lable: this.$t('labels.position'), key: "sort" },
        { label: this.$t('game_id'), key: "game.gameId" },
        { label: this.$t('brand'), key: "game.brandName" },
        { label: this.$t('labels.categories'), key: "game.categories[0].name" },
        { label: this.$t('labels.status'), key: "game.status" },
        { label: this.$t('labels.tags'), key: "game.tags" },
        { label: this.$t('labels.actions'), key: "actions" },
      ],
      typeOptions: [
        { key: "Agente", value: "Agent" },
        { key: "Usuario", value: "Player" },
      ],
      statusOptions: [
        { key: "bet", value: this.$t('status.bet') },
        { key: "return", value: this.$t('status.return') },
        { key: "bet_cancel", value: this.$t('status.bet_cancel') },
        { key: "cashout", value: this.$t('status.cashout') },
        { key: "lose", value: this.$t('status.lose') },
        { key: "win", value: this.$t('status.win') },
        { key: "cancel", value: this.$t('status.cancel') },
        { key: "rollback", value: this.$t('status.rollback') },
      ],
      /*       statusOptions: [
              { key: "bet", value: "xxxxxxxxx" },
              
            ], */
      loadingData: false,
      dataItems: [],
      products: [],
      games: [],
      brands: [],
      dataIdUser: [],
      pages: [20, 40, 60],
      search: "",
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      whiteLabelId: "",
      totalRows: 0,
      selectedProduct: "",
      customerOptions: [],
      currencyssOptions: [],
      ticketOptions: [],
      filterSelect: {
        transactionId: "",
        raceCourses: "",
        selectStatus: "",
        userType: "",
        transaction_status: "",
        dateFrom: moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      selectedBrand: "",
      img: "",
      exportFilter: {},
      width: window.innerWidth,
    };
  },
  watch: {
    whitelabelSelect(newValue) {
      this.games = [];
      this.getProducts();
    },
  },
  computed: {
    items() {
      if (this.games != undefined) {
        return this.games.filter((item) => {
          return this.checkGameName(item);
        });
      }
    },
  },
  // add filters
  filters: {
    moment: function (date) {
      if (date.includes("UTC")) {
        date = date.replace(" UTC", "");
      }
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },

  methods: {
    yearDropdownPlugin,

    checkGameName(item) {
      console.log(item)
      return item.gameName?.toLowerCase().includes(this.search.toLowerCase());
    },


    async getProducts(whitelabelId) {
      
      const VUE_APP_URL = process.env.VUE_APP_URL;
      this.whiteLabelId = whitelabelId;
      const response = await axios.get(
        `${VUE_APP_URL}/available-products/getBrandsIdsWhitelabels/${whitelabelId}`,
      );
      this.products = [...new Set(response.data)];
    },
    async updateSort(id, game, sort, productId) {
      this.loadingData = true;
      const VUE_APP_URL = process.env.VUE_APP_URL;
      const data = {
        "sort": sort
      }
      const response = await axios.patch(
        `${VUE_APP_URL}/available-games/${id}/${this.whiteLabelId}`,
        data
      );
      this.$toast({
          component: ToastificationContent,
          props: {
            title: "Update Success",
            icon: "CheckCirleIcon",
            variant: "success",
          },
        });
      this.loadingData = false;
    },
    async showData({ id: userId }) {
      this.dataIdUser = userId;
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        let date = {
          dateFrom: new Date(this.filterSelect.dateFrom),
          dateTo: new Date(this.filterSelect.dateTo),
        };
        const params = {
          whitelabelId: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          transactionId: this.filterSelect.transactionId,
          userId: this.dataIdUser,
          transaction_status: this.filterSelect.transaction_status,
          date: [date.dateFrom.toISOString(), date.dateTo.toISOString()],
          typeUser: this.filterSelect.userType,
        };
        this.exportFilter = params;
        const result = await axios.post(
          `${VUE_APP_URL}/betprime/report/ticket/history`,
          params
        );
        this.dataItems = result.data;
        this.Totals = result.data.length;
        this.dataItems.forEach((element) => {
          element.createdAt = element.createdAt + " UTC";
        });
        return result.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching data list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },

    onPageChange(page) {
      console.log(page, "page");
      this.serverParams.page = page;
      this.showData();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.showData();
    },
    async inputSelect(value) {
      this.loadingData = true;
      const VUE_APP_URL = process.env.VUE_APP_URL;
      if (value === 'products') {
        this.brands = []
      }
      for (let items of this.products) {
        if (items.productId === this.selectedProduct) {
          this.brands = items.brandIds
        }
      }

      const params = {
        idProduct: this.selectedProduct,
        idWhiteLabel: this.whiteLabelId,
        idBrand: this.selectedBrand
      }
      const result = await axios.post(
        `${VUE_APP_URL}/available-games/gamesByProductandBrand`,
        params
      );

      this.games = result.data;

      this.loadingData = false;
      /*  const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
     this.getProducts(whitelabelId) */
      /*  gamesByProductandBrand */
      /*   let id = this.dataIdUser;
  
        this.showData({ id }); */
    },
  },
  created() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  mounted() {
    const whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
    this.getProducts(whitelabelId)
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll {
  max-height: 600px !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>