import axios from '@axios'
const APP_URL = process.env.VUE_APP_URL
const GATEWAY_URL = process.env.VUE_APP_GATEWAY

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {

        fetchLobbys(ctx, queryParams) {

            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/lobbies`, {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        getDefaultLobby(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/admin-lobbies`, {
                        params
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchLobby(ctx, {
            id
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/lobbies/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        addLobby(ctx, lobbyData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/lobbies`, lobbyData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateLobby(ctx, {id, lobbyData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/lobbies/${id}`, lobbyData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeLobby(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/lobbies/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        /*Obtain Products types*/
        fetchProducts(ctx, clientId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/available-products/getBrandsIds/${clientId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
            // return new Promise((resolve, reject) => {
            //   axios
            //       .post(`/available-products/getBrandsIds/${clientId}`)
            //       .then(response => resolve(response))
            //       .catch(error => reject(error))
            // })
        },

        fetchProductsByWhitelabel(ctx, whitelabelId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/available-products/getBrandsIdsWhitelabels/${whitelabelId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchListTags(ctx, clientId) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/tags`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchLobbyImage(ctx,  id ) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/files/${id}?path=lobby`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        /*Obtain Categories types */
        fetchCategories(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/category`, {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTagsOptions(commit, search) {
            return new Promise((resolve, reject) => {
                return axios.get(`${GATEWAY_URL}/games/listtags/${search}`)
                    .then(response => {
                        resolve(response.data.tags)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
